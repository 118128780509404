/* global $, MatgenGlobal, Uint32Array */

import { Uploader } from '../../../../node_modules/@m4c/matgen/src/js/matgen-ui/components/Uploader.js';
import { v4 as UUID } from 'uuid';
import { faq_content } from '../../website-data-page/website-data/-components/website-content.js';
import { initLocationAutoComplete } from '../../website-data-page/website-data/display.js';
import { infoCard as ic } from './form-components/infoCard.js';
import { clearFormErrors } from '../../website-data-page/website-data/display.js';

export const infoCard = ic;

const useAutoComplete = false;

export const sectionHeader = (heading, subtext) => {
  return `
    <h3 class="color--blue-darker text-center"><span id="section-heading">${heading}</span></h3>
    ${
      subtext
        ? `<h6 id="section-subtext" class="text-center">${subtext}</h6>`
        : ''
    }
  `;
};

export const questionHeader = (data) => {
  const required = [
    '945d40e7-2c59-4b9a-9dc8-d14d7dcdd648',
    'b8cd8b13-d00f-4257-b1a9-41f4675e41bb',
    'cc8ad9ff-0a19-489c-954f-818ac1bad220',
    // '072d8175-47eb-41b7-a76b-a9415abb0949',
    '5881f812-2e0c-4eb2-bc85-5d5fd527983d',
    '2626b183-1ca5-4fb6-a5f0-a81f214369a3',
    'bf81ad48-b57a-47d1-b5a6-2a6ddf0a5e04',
    '2f63c960-5e6a-4597-90b7-843073e4bea9',
    'dd4a6415-3688-4ff5-a65a-c502a27b1fa9',
    '484fd598-2c6c-4476-9818-0535909d323f',
    '42d28066-b512-4aa8-ba56-860133909619',
    'e6eaf634-b6c7-4782-82ae-b8e27fcab3fa',
    'cc8ad9ff-0a19-489c-954f-818ac1bad220',
    '1264ea4e-bb78-4bdb-8011-2d6dd38c04c7',
    '533686f0-4114-4f3a-b1fc-79b789931a52',
    '655feb2b-3f57-42bf-9a22-c64caaf87fd1',
    '0b95b9f3-a825-4128-91a2-2aa5ba2d2b54',
    '900f8c49-b2c9-4d50-b1a5-c1535d734815',
    '0093e24d-d016-424a-9e68-d5d93d65da03',
    '498e0def-e28e-48ce-95c4-77d7e5553cf9',
    '4254468c-9221-4f26-8a3c-50fd33aaf33c',
  ].includes(data.id);
  return `<div class="head-and-sub-centered${required ? ' required' : ''}">
    <h5>${data.text}</h5>
    ${data.insert_sub_text && data.sub_text ? `<p>${data.sub_text}</p>` : ''}
    </div>
  `;
};

export const questionWrapper = (content) => {
  return `
    <div>
      ${content}
    </div>
  `;
};

export const textComponent = (data, srOnlyLabels = true) => {
  let addressAutoCompleteClass = '';
  if (data.label === 'Address Line 1') {
    addressAutoCompleteClass = ' auto';
  }
  return `
      <div class="mb-3">
        <label for="${data.id}" class="${
    srOnlyLabels ? 'sr-only' : ''
  } form-label${data.required ? ' required' : ''}">${
    data.placeholder ? data.placeholder : data.label
  }</label>
        <input
          type="${data.type}"
          class="form-control${
            data.maxlength ? ' char-limited' : ''
          }${addressAutoCompleteClass}"
          id="${data.id}"
          name="${data.id}"
          ${data.value ? `value="${data.value}"` : ''}
          ${data.required ? 'required' : ''}
          ${data.maxlength ? `data-max-chars="${data.maxlength}"` : ''}
          ${
            data.placeholder
              ? `placeholder="${data.placeholder}"`
              : data.label
              ? `placeholder="${data.label}"`
              : ''
          }
        >
      </div>
    `;
};

export const textAreaComponent = (data, srOnlyLabels = true) => {
  let placeholder = data.placeholder;
  if (data.insert_sub && data.sub_text) {
    placeholder = data.sub_text;
  }
  return `
    <div class="mb-3 textarea-wrapper">
      ${
        data.label
          ? `<label for="${data.id}" class="${
              srOnlyLabels ? 'sr-only' : ''
            } form-label${data.required ? ' required' : ''}">${
              data.label
            }</label>`
          : ''
      }
      <div class="placeholder-instructions">${
        placeholder ? `${placeholder}` : ''
      }</div>
      <textarea
        class="form-control char-limited"
        data-max-chars="${data.max_length ? data.max_length : '380'}"
        id="${data.id}"
        rows="${data.rows ? data.rows : '3'}"
        ${data.required ? ' required ' : ''}
      >${data.value ? data.value : ''}</textarea>
      <!-- Any space between textarea opening and closing tags will break HTML5 required validation  -->
      <div class="example-char-wrapper">
      <!--<a href="#" class="view-example" data-id="${
        data.id
      }">View Example</a>-->
      <p class="char-limit">Character limit: <span class="character-count" data-id="${
        data.id
      }">0</span>/${data.max_length ? data.max_length : '380'}</p>
      </div>
    </div>
    `;
};

export const boxSingleSelectComponent = (data, qid) => {
  const answers = data
    .map(
      (a) => `
      <div
        class="mb-3 microsite-select${
          a.userAnswer === true ? ' --selected' : ''
        }"
        data-question-id="${qid}"
        data-answer-id="${a.id}"
      >
        <h5>${a.text}</h5>
        <p>${a.sub_text}</p>
      </div>
    `
    )
    .join('');
  return answers;
};

export const cardSingleSelectComponent = (data, qid) => {
  const answers = data
    .map(
      (a) => `
      <div
        class="mb-3 microsite-select${
          a.userAnswer === true ? ' --selected' : ''
        }"
        data-question-id="${qid}"
        data-answer-id="${a.id}"
        tabindex="0"
      >
        <h5>${a.text}</h5>
        <p>${a.sub_text}</p>
      </div>
    `
    )
    .join('');
  return answers;
};

export const rangeComponent = (data) => {
  return `
    <div class="card card--basic card--has-title card--has-range">
      <div id="${data.id}"></div>
    </div>
  `;
};

export const buttonSelectComponent = (data) => {
  let qIndex = false;
  if (MatgenGlobal.generator) {
    qIndex =
      MatgenGlobal.generator.questionnaire.demographics.questions.findIndex(
        (el) => el.id === data.id
      );
  }
  //data.answers.sort((a, b) => a.sort_order - b.sort_order);
  let html = '';
  data.answers.forEach((option, i) => {
    html += `<button class="button--option select-hook${
      data.answers[i].userAnswer === true ? ' --selected' : ''
    }${data.component === 'select-multiple' ? ' multi' : ''}" data-answer-id="${
      data.answers[i].id
    }" data-question-id="${data.id}" data-component="${data.component}" id="${
      data.id
    }-option-${i}" aria-pressed="false"
    ${qIndex ? `data-question-index="${qIndex}"` : ''} data-option-index="${i}"
    type="button">${option.text}</button>`;
  });

  html = `
    <div id="${data.id}" class="card card--basic card--has-title card--multi-select">
      <div class="option-buttons">${html}</div>
    </div>
  `;

  return html;
};

export const dateRangeComponent = (data) => {
  return `<div class="row no-offset">
  <div class="col-sm-12 col-md-6">
    <input type="text" placeholder="Pick start date" id="${data.id}-start" class="start-date">
  </div>
  <div class="col-sm-12 col-md-6">
    <input type="text" placeholder="Pick end date" id="${data.id}-end" class="end-date">
  </div>
    </div>
  `;
};

export const standardSelectSingleComponent = (data) => {
  let html = '';
  for (let i = 0; i < data.options.length; i++) {
    html += `
      <option value="${data.options[i]}"${
      data.value && data.value !== '' && data.value === data.options[i]
        ? ' selected'
        : ''
    }>${data.options[i]}</option>
    `;
  }
  return `
    <div class="mb-3">
    <label for="${data.id}" class="form-label${
    data.required ? ' required' : ''
  }">${data.label}</label>
      <select class="form-select" id="${data.id}" aria-label="${
    data.label
  }" required>
        <option disabled selected value=''>--- Select ${data.label} ---</option>
        ${html}
      </select>
    </div>
  `;
};

export const optionalWrapper = (data, html) => {
  return `
    <div class="yes-no-wrapper" aria-labelledby="yes-no-wrapper">
    <div class="form-check yes">
      <input class="form-check-input" type="radio" value="yes" name="${data.id}" id="${data.id}-yes" checked aria-required="true">
      <label class="form-label form-check-label" for="${data.id}-yes">
        Yes
      </label>
    </div>
    <div class="form-check no">
      <input class="form-check-input" type="radio" value="no" name="${data.id}" id="${data.id}-no" aria-required="true">
      <label class="form-label form-check-label" for="${data.id}-no">
        No
      </label>
    </div>
    </div>
    <div id="optional-wrapper-${data.id}">
      ${html}
    </div>

  `;
};

export const addMultiInputListItem = (
  cur,
  qid,
  answer = false,
  push = false
) => {
  const newAnswers = {
    instanceId: UUID(),
    answers: [],
  };
  for (let i = 0; i < cur.answers.length; i++) {
    let newAnswer;
    if (answer.answers) {
      newAnswer = {
        text: cur.answers[i].text,
        sub_text: cur.answers[i].sub_text,
        id: answer.answers[i].id,
        sort_order: cur.answers[i].sort_order,
      };
      if (answer) {
        newAnswer.userAnswer = answer.answers[i].userAnswer;
      }
    } else {
      newAnswer = {
        text: cur.answers[i].text,
        sub_text: cur.answers[i].sub_text,
        id: UUID(),
        sort_order: cur.answers[i].sort_order,
      };
    }
    newAnswers.answers.push(newAnswer);
  }

  if (cur.userAnswers && push === true) {
    cur.userAnswers.push(newAnswers);
  }
  const response = multiInputComponent(
    { answers: newAnswers.answers },
    newAnswers.instanceId,
    qid
  );
  return response;
};

export const multiInputComponent = (data, instanceId, qid) => {
  let html = '';
  let init = false;
  let response;
  let temp;
  for (let i = 0; i < data.answers.length; i++) {
    const input = JSON.parse(data.answers[i].text);
    switch (input.type) {
      default:
        console.error(input);
        throw new Error('Invalid input type');
      case 'standard-select-single':
        input.id = data.answers[i].id;
        input.value = data.answers[i].userAnswer;
        html += standardSelectSingleComponent(input);
        break;
      case 'textarea':
        html += textAreaComponent(
          {
            ...input,
            id: data.answers[i].id,
            value: data.answers[i].userAnswer,
          },
          false
        );
        break;
      case 'text':
        html += textComponent(
          {
            ...input,
            id: data.answers[i].id,
            value:
              data.answers[i].userAnswer === 'DELETED'
                ? ''
                : data.answers[i].userAnswer,
          },
          false
        );
        temp = data.answers[i].text;
        try {
          temp = JSON.parse(temp);
        } catch (e) {
          console.error('BAD JSON:', temp);
        }
        break;
      case 'upload-single':
        response = singleUploadComponent({ ...input, id: data.answers[i].id });
        html += response.html;
        init = response.init;
        break;
    }
  }
  if (qid === MatgenGlobal.locationsQuestionId) {
    if (
      useAutoComplete &&
      !(
        data.answers[0].userAnswer &&
        data.answers[0].userAnswer !== '' &&
        data.answers[1].userAnswer &&
        data.answers[1].userAnswer !== ''
      )
    ) {
      const id = UUID();
      html = `
        <div class="location-wrapper">
          <div class="location-autocomplete">
            <form autocomplete="off">
              <label for="${id}" class="form-label">Find an address (Or skip this step and enter manually below)</label>
              <input type="text" class="form-control auto pac-target-input" id="${id}" name="${id}" placeholder="Start typing..." autocomplete="off">
            </form>
          </div>
          <div class="location-inputs">
            ${html}
          </div>
        </div>
      `;
      init = () => {
        initLocationAutoComplete(id);
      };
    }
  }
  html = `
    <div id="${instanceId}" class="multi-input-wrapper" data-question-id="${qid}">
      <div class="delete-btn-wrapper">
        <a href="#" class="remove-multi-input trash-link" data-instance-id="${instanceId}" data-question-id="${qid}" aria-label="delete button"><i class="fa-solid fa-trash-can fa-2xl"></i></a>
      </div>
      ${html}
    </div>
  `;
  return { html, init };
};

async function editUpload(file, uploader, id, obj) {
  if (file) {
    const durl = await uploader.bufferUpload(file);
    uploader.mimeType = durl.substring(
      durl.indexOf(':') + 1,
      durl.indexOf(';')
    );

    MatgenGlobal.M4CModal.show({
      id: 'cropper-modal',
      title: 'Crop/Confirm Image',
      content: MatgenGlobal.UI.uploaderContent(),
      buttons: [
        {
          id: 'save-upload-crop',
          classname: 'primary btn btn-primary',
          label: 'Continue',
        },
      ],
    });

    $(`#upload-${id}`).remove();
    const img = await uploader.cropper.result;
    $('body').append(
      $(`
        <input type="hidden" id="upload-${id}" value="${img.toDataURL(
        uploader.mimeType
      )}" />
      `)
    );

    $('#cropper-modal').modal('hide');

    MatgenGlobal.UI.initCroppie(uploader, obj, durl);
  }
}

export const singleUploadComponent = (data) => {
  const uploader = new Uploader();
  const answers = Array.isArray(data.answers)
    ? data.answers
    : data.answers.answer;
  const id = 'microsite-uploader';

  const html = `
  <div class="upload-section">
    <div id="drop-area-${data.id}" class="upload-area">
        <div class="uploader-preview" id="uploader-preview-${data.id}" style="height:100%;"><p class="text-center">Place image here</p></div>
    </div>
    <a href="#" id="${data.id}-link" class="upload-link">Upload from computer<a>
  </div>
  `;

  /*if (answers && (answers[0].yesNo || answers[0].text)) {
    html = optionalWrapper(data, html);
  }*/

  const init = () => {
    if (answers && answers[0].userAnswer) {
      $(`#uploader-preview-${data.id}`).empty();
      $('<img id="website-image" />')
        .appendTo(`#uploader-preview-${data.id}`)
        .attr('src', answers[0].userAnswer)
        //.addClass('img-fluid')
        .css({
          maxHeight: '100%',
          width: 'auto',
          maxWidth: '100%',
          height: 'auto',
          margin: 'auto',
        });

      $(`#${data.id}-link`).text('Remove uploaded file');

      $(`#upload-${data.id}`).remove();
      $('body').append(
        $(`
        <input type="hidden" id="upload-${data.id}" value="${answers[0].userAnswer}" />
      `)
      );
    }

    const dropArea = $(`#drop-area-${data.id}`)[0];

    ['dragenter', 'dragover', 'dragleave'].forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults, false);
    });

    function preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    }

    $(`#drop-area-${data.id}`).on('drop', (e) => {
      e.preventDefault();
      e.stopPropagation();
      clearFormErrors($(`#optional-wrapper-${data.id}`));
      const dt = e.originalEvent.dataTransfer;
      const file = dt.files[0];
      const obj = {
        width: $('#website-image').width(),
        height: $('#website-image').height(),
        scaleX: 1,
        scaleY: 1,
      };
      editUpload(file, uploader, data.id, obj);
    });

    $(`#${data.id}-link`).on('click keypress', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();
        if ($(`#${data.id}-link`).text() === 'Upload from computer') {
          clearFormErrors($(`#optional-wrapper-${data.id}`));
          uploader.fileSelect(id, () => {
            const file = document.getElementById(id).files[0];
            const obj = {
              width: $('#website-image').width(),
              height: $('#website-image').height(),
              scaleX: 1,
              scaleY: 1,
            };
            editUpload(file, uploader, data.id, obj);
          });
        } else {
          $(`#uploader-preview-${data.id}`).empty();
          $(`#${data.id}-link`).text('Upload from computer');
          $(`#upload-${data.id}`).remove();
        }
      }
    });

    if (answers) {
      try {
        if (answers[0].yesNo || answers[0].text) {
          $(`[name="${data.id}"]`).on('click keypress', (e) => {
            if (MatgenGlobal.UI.a11yClick(e) === true) {
              clearFormErrors($(`#optional-wrapper-${data.id}`));
              if ($(e.target).val() === 'yes') {
                $(`#optional-wrapper-${data.id}`).show();
              } else {
                $(`#optional-wrapper-${data.id}`).hide();
                //Commented out below line to fix content clear when toggling yes/no
                // $(`#uploader-preview-${data.id}`).empty();
                $(`#upload-${data.id}`).remove();
              }
            }
          });
        }
      } catch (e) {
        console.error('Bad answer JSON', data);
      }
    }
  };
  return { html, init };
};

export const faqListItem = ({
  id,
  text,
  answer,
  qid,
  rows,
  sub_text,
  sort_order,
} = {}) => {
  return `
    <li id="${id}-li" class="drag-tile faq-list-item" tabindex="0">
      <div class="faq-wrapper-left">
        <a href="#" class="faq-sort" data-faq-id="faq-${id}">
          <div class="faq-wrapper-sort-target">
          <span data-toggle="tooltip" data-placement="top" title="Move Up" class="faq-sort-icon faq-sort-icon-up" ><i class="fa-solid fa-triangle fa-xl"></i></span>
          <span class="faq-sort-number">${sort_order + 1}</span>
          <span data-toggle="tooltip" data-placement="top" title="Move Down" class="faq-sort-icon faq-sort-icon-down"><i class="fa-solid fa-triangle fa-xl"></i></span>
          </div>
        </a>
      </div>
      <div class="faq-wrapper">
        <div class="faq-wrapper-right">
          <div class="faq-wrapper-top">
            <div class="text-block">
              <p class="text">${text}</p>
              <p class="sub_text">${sub_text ? sub_text : ''}</p>
            </div>
            <div class="faq-wrapper-delete">
              <a href="#" class="remove-faq trash-link" data-answer-id="${id}" data-question-id="${qid}" aria-label="faq delete button"><i class="fa-solid fa-trash-can fa-2xl"></i></a>
            </div>
            </div>
            <div>
          </div>
          <textarea
            class="form-control"
            id="${id}-answer"
            rows="${rows ? rows : '3'}"
            required
            data-translated-question="${text}"
          >${answer ? answer : ''}</textarea>
        </div>
      </div>
    </li>
  `;
};

export const faqListItemOld = (id, text, answer, qid, rows, sub_text) => {
  return `
    <li id="${id}-li" class="drag-tile faq-list-item" tabindex="0">
      <div class="node-grip">
        <div class="row node-row">
          <div class="col-sm-12 col-md-9 top-area">
            <i class="fa-solid fa-up-down"></i>
            <div class="text-block">
                <p class="text">${text}</p>
                <p class="sub_text">${sub_text ? sub_text : ''}</p>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
            <button class="button--orange button--muted remove-faq" data-answer-id="${id}" data-question-id="${qid}" aria-label="faq list delete button">Delete</button>
          </div>
        </div>
      </div>
      <div>
        <textarea
          class="form-control"
          id="${id}-answer"
          rows="${rows ? rows : '3'}"
          required
          placeholder="Answer..."
          data-translated-question="${text}"
        >${answer ? answer : ''}</textarea>
      </div>
    </li>
  `;
};

export const faqListComponent = (data) => {
  let items = '';

  //data.answers.sort((a, b) => a.sort_order - b.sort_order);
  if (data.userAnswers) {
    for (let i = 0; i < data.userAnswers.length; i++) {
      const langQuestion = Object.values(
        MatgenGlobal.currentStudy.questions
      ).find((q) => q.id === '99120986-a88a-46c1-ae87-2af83030740e');
      const langAnswer = langQuestion.answers.find(
        (a) => a.userAnswer === true
      );
      let lang = false;
      if (langAnswer) {
        lang = langAnswer.text;
      }
      items += faqListItem({
        id: data.userAnswers[i].id,
        text:
          lang &&
          faq_content[lang][i] &&
          data.userAnswers[i].text === faq_content.English[i]
            ? faq_content[lang][i]
            : data.userAnswers[i].text,
        answer: data.userAnswers[i].userAnswer,
        qid: data.id,
        rows: null,
        sub_text: data.userAnswers[i].text.sub_text,
        sort_order: data.userAnswers[i].sort_order,
      });
    }
  } else {
    if (data.answers) {
      for (let i = 0; i < data.answers.length; i++) {
        const langQuestion = Object.values(
          MatgenGlobal.currentStudy.questions
        ).find((q) => q.id === '99120986-a88a-46c1-ae87-2af83030740e');
        const langAnswer = langQuestion
          ? langQuestion.answers.find((a) => a.userAnswer === true)
          : false;
        let lang = false;
        if (langAnswer) {
          lang = langAnswer.text;
        }

        console.log(data);
        items += faqListItem({
          id: data.answers[i].id,
          text:
            lang &&
            faq_content[lang][i] &&
            data.answers[i].text === faq_content.English[i]
              ? faq_content[lang][i]
              : data.answers[i].text,
          answer: data.answers[i].userAnswer,
          qid: data.id,
          rows: null,
          sub_text: data.answers[i].sub_text,
          sort_order: data.answers[i].sort_order,
        });
      }
    }
  }

  return `
    <ul id="${data.id}" class="sortable-tiles" role="listbox">
      ${items}
    </ul>
    <button id="add-faq" data-question-id="${data.id}" tabindex="0" type="button" class="btn btn-primary multi-add-btn">+ Add your own question</button>
  `;
};

export const imageSelectComponent = (data, base_dir) => {
  let items = '';
  const array = new Uint32Array(10);

  for (let i = 0; i < data.answers.length; i++) {
    self.crypto.getRandomValues(array);
    items += `<div class="col-4">
    <img class="holder-image${
      data.answers[i].userAnswer === true ? ' selected' : ''
    }" data-question-id="${data.id}" id="${
      data.answers[i].id
    }" src="${base_dir}${JSON.parse(data.answers[i].text).dir}/${
      JSON.parse(data.answers[i].text).src
    }?${array.join('')}" tabindex="0"/>
    </div>
    `;
  }

  const html = `
    <div id="${data.id}" class="row anchored">
      ${items}
    </div>
  `;
  const init = () => {
    /*Holder.run({
      images: '.holder-image',
    });*/
  };
  return { html, init };
};

export const listSingleSelectComponent = (data) => {
  let items = '';
  //data.answers.sort((a, b) => a.sort_order - b.sort_order);
  for (let i = 0; i < data.answers.length; i++) {
    items += `
    <div class="microsite-select title-select ${
      data.answers[i].userAnswer === true ? ' --selected' : ''
    }" data-question-id="${data.id}" data-answer-id="${
      data.answers[i].id
    }" id="${data.answers[i].id}" tabindex="0">
      <h5>${data.answers[i].text}</h5>
      ${data.answers[i].sub_text ? `<p>${data.answers[i].sub_text}</p>` : ''}
    </div>
    `;
  }
  const html = `
    <div id="${data.id}" class="q-flex">
      ${items}
    </div>
  `;

  return { html };
};
