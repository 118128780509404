/* global MatgenGlobal, Set */

import { authHeaderOpts } from '../../+auth-global/data/index.js';

class Study {
  constructor(studyId) {
    this.id = studyId;
    this.state = {
      dataEntered: false,
      templateChosen: false,
      irbDownloaded: false,
      irbUploaded: false,
      niaApproved: false,
      websiteLaunched: false,
      templateButtonActive: false,
      subdomainButtonActive: false,
      downloadButtonActive: false,
      uploadButtonActive: false,
      launchButtonActive: false,
      pendingDataEdits: false,
      pendingTemplateEdits: false,
      downloadUpToDate: false,
      uploadUpToDate: false,
    };

    return (async () => {
      console.error('STUDY CONSTRUCTOR', this.id, MatgenGlobal.AuthUser);
      if (this.id && MatgenGlobal.AuthUser) {
        try {
          const opts = await authHeaderOpts();
          let study = await MatgenGlobal.Amplify.API.get(
            'authenticated',
            `/user-folders/${this.id}`,
            opts
          );
          if (study && Array.isArray(study) && study.length > 0) {
            study = study[0];
          } else {
            study = false;
          }
          this.study = study;
          let website = await MatgenGlobal.Amplify.API.get(
            'authenticated',
            `/websites?user_folder_id=${this.id}`,
            opts
          );
          if (website && Array.isArray(website) && website.length > 0) {
            website = website[0];
          } else {
            website = false;
          }
          this.website = website;

          const allQuestions = await MatgenGlobal.Data.API.request(
            '/questions?s=website'
          );

          const websiteQuestions = allQuestions.filter(
            (q) => q.questionnaire_type === 'microsite'
          );
          MatgenGlobal.websiteQuestions = websiteQuestions;
          const templateQuestions = allQuestions.filter(
            (q) => q.questionnaire_type === 'template'
          );
          websiteQuestions.sort((a, b) => {
            return (
              a.section_order - b.section_order ||
              a.question_order - b.question_order ||
              a.answer_order - b.answer_order
            );
          });

          const unifiedSortedQuestions = [];
          const questionOrder = {
            phase: ['about'],
            health2: ['who', 'involved', 'why'],
            howmany: ['howmany'],
          };
          for (let i = 0; i < websiteQuestions.length; i++) {
            unifiedSortedQuestions.push(websiteQuestions[i]);
            if (questionOrder[websiteQuestions[i].handle]) {
              for (
                let j = 0;
                j < questionOrder[websiteQuestions[i].handle].length;
                j++
              ) {
                const q = templateQuestions.find(
                  (q) =>
                    q.handle === questionOrder[websiteQuestions[i].handle][j]
                );
                if (q) {
                  unifiedSortedQuestions.push(q);
                }
              }
            }
          }

          this.questionnaire = unifiedSortedQuestions;
          const ids = [
            ...new Set(unifiedSortedQuestions.map((q) => q.question_id)),
          ];
          const examples = await MatgenGlobal.Data.API.request(
            '/question-examples',
            'POST',
            { ids }
          );

          for (let i = 0; i < examples.length; i++) {
            const question = this.questionnaire.find(
              (q) => q.question_id === examples[i].question_id
            );
            if (question) {
              if (!question.examples) {
                question.examples = [];
              }
              question.examples.push(examples[i]);
            }
          }

          const questions = {};

          for (let i = 0; i < this.questionnaire.length; i++) {
            if (!questions[this.questionnaire[i].question_id]) {
              questions[this.questionnaire[i].question_id] = {
                id: this.questionnaire[i].question_id,
                component: this.questionnaire[i].component,
                text: this.questionnaire[i].question_text,
                sub_text: this.questionnaire[i].question_sub_text,
                instructions: this.questionnaire[i].instructions,
                insert_sub_text: this.questionnaire[i].question_insert_sub_text,
                section_type: this.questionnaire[i].section_type,
                section_name: this.questionnaire[i].section_name,
                answers: [
                  {
                    id: this.questionnaire[i].answer_id,
                    text: this.questionnaire[i].answer_text,
                    sub_text: this.questionnaire[i].answer_sub_text,
                    sort_order: this.questionnaire[i].answer_order,
                  },
                ],
                examples: this.questionnaire[i].examples,
              };
            } else {
              questions[this.questionnaire[i].question_id].answers.push({
                id: this.questionnaire[i].answer_id,
                text: this.questionnaire[i].answer_text,
                sub_text: this.questionnaire[i].answer_sub_text,
                instructions: this.questionnaire[i].instructions,
                insert_sub_text: this.questionnaire[i].question_insert_sub_text,
                sort_order: this.questionnaire[i].answer_order,
                examples: this.questionnaire[i].examples,
                section_type: this.questionnaire[i].section_type,
                section_name: this.questionnaire[i].section_name,
              });
            }
          }

          this.questions = questions;

          this.answers = await MatgenGlobal.Data.API.request(
            `/user-content?user_folder_id=${this.id}`
          );

          this.dataLastEdited =
            this.answers && this.answers.length > 0
              ? new Date(
                  Math.max(...this.answers.map((a) => new Date(a.updated)))
                )
              : false;

          this.micrositeTemplateIds = [
            '00000000-0000-0000-0000-000000000001',
            '00000000-0000-0000-0000-000000000002',
            '00000000-0000-0000-0000-000000000003',
          ];

          await this.loadMicrosite();
          await this.loadDownloaded();
          await this.loadIRBData();

          //console.log('NEW STUDY:', this);
        } catch (e) {
          console.error(e);
        }
        //console.log('LOAD STUDY STATE', this);
      } else if (!MatgenGlobal.AuthUser) {
        console.error('NO LOGGED IN USER');
      } else if (!this.id) {
        console.error('NO STUDY ID');
      }

      // Constructors return `this` implicitly, but this is an IIFE, so
      // return `this` explicitly (else we'd return an empty object).
      return this;
    })();
  }

  async getMicrosite() {
    return MatgenGlobal.Amplify.API.get(
      'public',
      `/websites?user_folder_id=${this.study.id}`
    );
  }

  async loadMicrosite() {
    try {
      this.microsite = await this.getMicrosite();
      this.microsite =
        this.microsite &&
        Array.isArray(this.microsite) &&
        this.microsite.length > 0
          ? this.microsite[0]
          : false;
    } catch (e) {
      this.microsite = false;
    }
  }

  async loadDownloaded() {
    try {
      this.downloaded = await this.getDownloaded();
      if (this.downloaded && Array.isArray(this.downloaded)) {
        this.downloaded = this.downloaded[0];
      }
    } catch (e) {
      this.downloaded = false;
    }
  }

  async getDownloaded() {
    //console.error('GET DOWNLOADED:', this);
    return MatgenGlobal.Data.API.request(`/downloads/${this.study.id}`);
  }

  async getIRBData() {
    return MatgenGlobal.Data.API.request(
      `/irb_data?microsite_id=${this.website.id}`,
      'GET'
    );
  }

  async loadIRBData() {
    try {
      this.irb_data = await this.getIRBData();
      //console.log(this.irb_data);
      if (Array.isArray(this.irb_data) && this.irb_data.length > 0) {
        this.irb_data = this.irb_data[0];
      } else {
        this.irb_data = false;
      }
    } catch (e) {
      this.irb_data = false;
    }
  }
}

export default Study;
