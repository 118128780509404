/* global $ */

import { v4 as UUID } from 'uuid';
import { content, faq_content } from './website-content.js';

export const sanitizeHTML = (str) => {
  if (str && typeof str === 'string') {
    return str.replace(/[^\w. ]/gi, (c) => {
      return `&#${c.charCodeAt(0)};`;
    });
  } else {
    return '';
  }
};

export const MicrositeTemplate = async (data) => {
  //console.log('MICROSITE DATA:', data);
  const manifest = await $.getJSON(`${data.base_url}manifest.json`);
  const js = manifest['main.js'].replace('/assets/js/', '');
  const css = manifest['main.css'].replace('/assets/css/', '');
  if (!data.template_version) {
    data.template_version = 0;
  }
  if (data.template_version === 0) {
    data.hero_image = data.hero_image.replace('.jpg', '.png');
    data.hero_dir = 'CircularHero';
  }
  data.hero_image = `${data.hero_dir}/${data.hero_image}`;

  window.mdata = data;
  window.content = content;

  return `

  <head>
      <!-- Required meta tags -->
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">

      <!-- Bootstrap CSS -->
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet"
          integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous">
      <!-- should use gulp to hash filenames and inject
      <link href="./dist/styles.css" rel="stylesheet"> -->
      <link rel="preconnect" href="https://fonts.gstatic.com">
      ${
        data.template_version === 0
          ? `
          <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet">
          <style>
          :root {
            --primary-bg-color: #DAEEF2;
            --secondary-bg-color: #F4FAFB;
            --primary-text-color: #003740;
            --primary-font-family: 'Roboto', sans-serif;
          }
          </style>
          `
          : ''
      }
      ${
        data.template_version === 1
          ? `
          <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet">
          <style>
          :root {
            --primary-bg-color: #AB54A6;
            --secondary-bg-color: #FAF5FA;
            --primary-text-color: #003740;
            --primary-font-family: 'Montserrat', sans-serif;
          }
          </style>
          `
          : ''
      }
      ${
        data.template_version === 2
          ? `
          <link
          href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet">
          <style>
          :root {
            --primary-bg-color: #BCE3B5;
            --secondary-bg-color: #F2F9F0;
            --primary-text-color: #003740;
            --primary-font-family: 'Montserrat', sans-serif;
          }
          </style>
          `
          : ''
      }

    <title>${data.study_name}</title>
    <base href="${data.base_url}">
    <script defer="defer" src="${data.base_url}assets/js/${js}"></script>
    <link href="${data.base_url}assets/css/${css}" rel="stylesheet">

  </head>

  <body>

      <section class="header-section">
          <div class="container">
              <div class="row d-flex align-items-center small-center">
              ${
                data.study_name
                  ? `
                  <div class="col-sm-12 col-md-6">
                      <p class="p--4"><strong>${
                        content[data.study_language].title
                      }:</strong> ${data.study_name}</p>
                  </div>
                  `
                  : ''
              }
                  <div class="col-sm-12 col-md-6">
                      <!--<img class="nia-logo" src="assets/img/nia_logo.svg" />-->
                  </div>
              </div>
          </div>
      </section>
      <div class="container content-wrapper">
      <section class="hero" id="hero-${data.template_version}">
          <div class="container">
              <div class="row">
                  <div class="col-sm-12 col-md-7">
                      <h1>${
                        content[data.study_language].website_title[
                          content.English.website_title.findIndex(
                            (a) =>
                              sanitizeHTML(a) ===
                              sanitizeHTML(data.website_title)
                          )
                        ]
                          ? content[data.study_language].website_title[
                              content.English.website_title.findIndex(
                                (a) =>
                                  sanitizeHTML(a) ===
                                  sanitizeHTML(data.website_title)
                              )
                            ]
                          : ''
                      }</h1>
                      <p class="p--6">${
                        content[data.study_language].website_subtitle[
                          content.English.website_subtitle.findIndex(
                            (a) =>
                              sanitizeHTML(a) ===
                              sanitizeHTML(data.website_subtitle)
                          )
                        ]
                          ? content[data.study_language].website_subtitle[
                              content.English.website_subtitle.findIndex(
                                (a) =>
                                  sanitizeHTML(a) ===
                                  sanitizeHTML(data.website_subtitle)
                              )
                            ]
                          : ''
                      }</p>
                  </div>
                  <div class="col-sm-12 col-md-5">
                      <span class="hero-img" style="background-image: url('assets/img/${
                        data.hero_image
                      }')"></span>
                  </div>
              </div>
          </div>
      </section>
      <div class="inner-wrapper">
      <section class="intro">
          <div class="container">
              <div class="row">
                  <div class="col-12">
                      <h4>${content[data.study_language].welcome}</h4>
                      <p class="p--5">${data.welcome}</p>
                  </div>
              </div>
          </div>
      </section>

      <section class="main">
          <div class="container">
              <div class="row">
                  <div class="col-sm-12 col-md-4 page-nav">
                      <div class="sticky">
                          <div id="nav-section-1">
                              <a href="#body-section-1">
                                  <p class="p--5">${
                                    content[data.study_language].details
                                  }</p>
                              </a>
                              <a href="#body-section-1-subset-1">
                                  <p class="p--4">${
                                    content[data.study_language].name
                                  }</p>
                              </a>

                              ${
                                (data.phase_text &&
                                  data.phase_text.length > 0) ||
                                (data.study_goal && data.study_goal.length > 0)
                                  ? `
                              <a href="#body-section-1-subset-2">
                                  <p class="p--4">${
                                    content[data.study_language].about
                                  }</p>
                              </a>
                              `
                                  : ``
                              }
                              <a href="#body-section-1-subset-3">
                                  <p class="p--4">${
                                    content[data.study_language].right
                                  }</p>
                              </a>
                              ${
                                data.what_happens
                                  ? `
                                <a href="#body-section-1-subset-4">
                                    <p class="p--4">${
                                      content[data.study_language].happen
                                    }</p>
                                </a>
                                `
                                  : ''
                              }
                              ${
                                data.how_long
                                  ? `
                                <a href="#body-section-1-subset-5">
                                    <p class="p--4">${
                                      content[data.study_language].long
                                    }</p>
                                </a>
                                `
                                  : ''
                              }
                              ${
                                data.who_with
                                  ? `
                                <a href="#body-section-1-subset-5a">
                                    <p class="p--4">${
                                      content[data.study_language].with
                                    }</p>
                                </a>
                                `
                                  : ''
                              }
                              ${
                                data.benefits_risks
                                  ? `
                                <a href="#body-section-1-subset-6">
                                    <p class="p--4">${
                                      content[data.study_language].risks
                                    }</p>
                                </a>
                                `
                                  : ''
                              }
                              ${
                                data.provided
                                  ? `
                                <a href="#body-section-1-subset-7">
                                    <p class="p--4">${
                                      content[data.study_language].provided
                                    }</p>
                                </a>
                                `
                                  : ''
                              }
                              ${
                                data.funding
                                  ? `
                                <a href="#body-section-1-subset-8a">
                                    <p class="p--4">${
                                      content[data.study_language].funding
                                    }</p>
                                </a>
                                `
                                  : ''
                              }
                              ${
                                data.investigators &&
                                data.investigators.length > 0
                                  ? `
                                <a href="#body-section-1-subset-8">
                                    <p class="p--4">${
                                      content[data.study_language].investigators
                                    }</p>
                                </a>
                                `
                                  : ''
                              }
                              ${
                                data.locations && data.locations.length > 0
                                  ? `
                                <a href="#body-section-1-subset-9">
                                    <p class="p--4">${
                                      content[data.study_language].locations
                                    }</p>
                                </a>
                                `
                                  : ''
                              }

                          </div>
                          <div id="nav-section-2">
                              <a href="#body-section-2">
                                  <p class="p--5">${
                                    content[data.study_language].contact
                                  }</p>
                              </a>
                          </div>
                          <div id="nav-section-3">
                              <a href="#body-section-3">
                                  <p class="p--5">${
                                    content[data.study_language].what
                                  }</p>
                              </a>
                          </div>
                          <div id="nav-section-4">
                              <a href="#body-section-4">
                                  <p class="p--5">${
                                    content[data.study_language].why
                                  }</p>
                              </a>
                          </div>
                          ${
                            data.faq && data.faq.length > 0
                              ? `<div id="nav-section-5">
                              <a href="#body-section-5">
                                  <p class="p--5">${
                                    content[data.study_language].faq
                                  }</p>
                              </a>
                          </div>`
                              : ''
                          }
                          ${
                            data.logo
                              ? `
                                <div id="nav-section-6">
                                    <div class="upload-logo">
                                        <img class="img-fluid" src="${data.logo}" />
                                    </div>
                                </div>
                              `
                              : ''
                          }
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                      <div id="body-section-1">
                          <h4>${content[data.study_language].details}</h4>
                          <div name="body-section-1-subset-1" id="body-section-1-subset-1" class="subset">
                              <h6 class="heading">${
                                content[data.study_language].name
                              }</h6>
                              <div class="content">
                                  <h4>${data.study_name}</h4>
                              </div>
                          </div>


                            ${
                              (data.phase_text && data.phase_text.length > 0) ||
                              (data.study_goal && data.study_goal.length > 0)
                                ? `
                          <div id="body-section-1-subset-2" class="subset">
                              <h6 class="heading">${
                                data.study_goal && data.study_goal.length > 0
                                  ? content[data.study_language].about
                                  : ''
                              }</h6>
                              <div class="content">
                                  <p class="p--4">${data.study_goal}</p>

                                  ${
                                    data.phase_text &&
                                    data.phase_text.length > 0
                                      ? `
                                    <div class="callout-box">
                                        <h6>${
                                          content[data.study_language]
                                            .phase_text[
                                            content.English.phase_text.findIndex(
                                              (a) => {
                                                return (
                                                  sanitizeHTML(a) ===
                                                  sanitizeHTML(data.phase_text)
                                                );
                                              }
                                            )
                                          ]
                                        }</h6>
                                        <p class="p--4">${
                                          content[data.study_language]
                                            .phase_sub_text[
                                            content.English.phase_text.findIndex(
                                              (a) =>
                                                sanitizeHTML(a) ===
                                                sanitizeHTML(data.phase_text)
                                            )
                                          ]
                                        }</p>
                                    </div>
                                    `
                                      : ''
                                  }

                              </div>
                          </div>`
                                : ``
                            }
                          <div id="body-section-1-subset-3" class="subset">
                              <h6 class="heading">${
                                content[data.study_language].right
                              }</h6>
                              <div class="content">
                                  <p class="p--4">
                                    ${content[data.study_language].eligible}
                                  </p>
                                  <p class="p--4"><strong>${
                                    content[data.study_language].minage
                                  }:</strong> ${data.min_age} ${
    content[data.study_language].yrsold
  }</p>
                                  <p class="p--4"><strong>${
                                    content[data.study_language].maxage
                                  }:</strong> ${data.max_age} ${
    content[data.study_language].yrsold
  }</p>
                                  <p class="p--4"><strong>${
                                    content[data.study_language].gender
                                  }:</strong> ${data.gender
    .map((a) => {
      return content[data.study_language].gender_options[
        content.English.gender_options.findIndex(
          (b) => sanitizeHTML(b) === sanitizeHTML(a)
        )
      ];
    })
    .join(', ')}</p>
                                  <!--<p class="p--4"><strong>Race and/or Ethnicity:</strong> Hispanic or Latino, African
                                      American</p>-->
                                  <p class="p--4"><strong>${
                                    content[data.study_language].health_status
                                  }:</strong> ${data.health_status
    .map((a) => {
      return content[data.study_language].health_status_options[
        content.English.health_status_options.findIndex((b) => {
          return b === a;
        })
      ];
    })
    .join(', ')}</p>
                                  <!--<p class="p--4"><strong>Enrollment:</strong> 2000</p>-->
                                  ${
                                    data.conditions
                                      ? `
                                    <p class="p--4"><strong>${
                                      content[data.study_language].condition
                                    }</strong> ${data.conditions}</p>
                                    `
                                      : ''
                                  }
                                  ${
                                    data.requirements
                                      ? `
                                    <div class="accordion" id="accordion1">
                                        <div class="accordion-item">
                                            <h6 class="accordion-header" id="heading1">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapse1" aria-expanded="true"
                                                    aria-controls="collapse1">
                                                    ${
                                                      content[
                                                        data.study_language
                                                      ].add
                                                    }
                                                </button>
                                            </h6>
                                            <div id="collapse1" class="accordion-collapse collapse ${
                                              data.showAccordions ? 'show' : ''
                                            }"
                                                aria-labelledby="heading1" data-bs-parent="#accordion1">
                                                <div class="accordion-body">
                                                    <p class="p--4">${
                                                      data.requirements
                                                    }</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    `
                                      : ''
                                  }

                              </div>
                          </div>
                          ${
                            data.what_happens
                              ? `
                                <div id="body-section-1-subset-4" class="subset">
                                    <h6 class="heading">${
                                      content[data.study_language].happen
                                    }</h6>
                                    <div class="content">
                                        <p class="p--4">${data.what_happens}</p>

                                        <!--<div class="graphic">
                                            <p class="p--5">Process Graphic</p>
                                        </div>-->
                                    </div>
                                </div>
                              `
                              : ''
                          }

                          ${
                            data.how_long
                              ? `
                            <div id="body-section-1-subset-5" class="subset">
                                <h6 class="heading">${
                                  content[data.study_language].long
                                }</h6>
                                <div class="content">
                                    <p class="p--4">${data.how_long}</p>
                                </div>
                            </div>
                            `
                              : ''
                          }

                          ${
                            data.who_with
                              ? `
                            <div id="body-section-1-subset-5a" class="subset">
                                <h6 class="heading">${
                                  content[data.study_language].with
                                }</h6>
                                <div class="content">
                                    <p class="p--4">${data.who_with}</p>
                                </div>
                            </div>
                            `
                              : ''
                          }

                          ${
                            data.benefits_risks
                              ? `
                            <div id="body-section-1-subset-6" class="subset">
                                <h6 class="heading">${
                                  content[data.study_language].risks
                                }</h6>
                                <div class="content">
                                    <p class="p--4">${data.benefits_risks}</p>
                                </div>
                            </div>
                            `
                              : ''
                          }
                          ${
                            data.provided
                              ? `
                            <div id="body-section-1-subset-7" class="subset">
                                <h6 class="heading">${
                                  content[data.study_language].provided
                                }</h6>
                                <div class="content">
                                    <p class="p--4">${data.provided}</p>
                                </div>
                            </div>
                            `
                              : ''
                          }

                          <div id="body-section-1-subset-8a" class="subset">
                              <h6 class="heading">${
                                content[data.study_language].funding
                              }</h6>
                              <div class="content">
                                  <p class="p--4">${data.funding}</p>
                              </div>
                          </div>

                          ${
                            data.investigators && data.investigators.length > 0
                              ? `
                            <div id="body-section-1-subset-8" class="subset">
                                <h6 class="heading">${
                                  content[data.study_language].investigators
                                }</h6>
                                <div class="content">
                                  ${data.investigators
                                    .map((a) => {
                                      //console.log('MAPPING:', a);
                                      return `
                                      <div class="callout-box">
                                      ${a
                                        .map(
                                          (ua, i) => `
                                            ${
                                              ua.value && ua.value !== ''
                                                ? `<p class="p--4">
                                                ${i === 0 ? '<strong>' : ''}
                                                ${ua.value}
                                                ${i === 0 ? '</strong>' : ''}
                                                </p>`
                                                : ''
                                            }
                                          `
                                        )
                                        .join('')}
                                      </div>
                                    `;
                                    })
                                    .join('')}
                                    <!--<h6>${
                                      content[data.study_language].primary
                                    }</h6>
                                    <div class="callout-box">
                                        <p class="p--4"><strong>${
                                          data.primary_contact[0]
                                        }</strong></p>
                                        <p class="p--4">${
                                          data.primary_contact[1]
                                        }</p>
                                        <p class="p--4">Email: ${
                                          data.primary_contact[2]
                                        }</p>
                                    </div>-->
                                </div>
                            </div>
                            `
                              : ''
                          }

                          ${
                            data.locations &&
                            Object.keys(data.locations).length > 0
                              ? `
                            <div id="body-section-1-subset-9" class="subset">
                                <h6 class="heading">${
                                  content[data.study_language].locations
                                }</h6>
                                <div class="content">

                                    ${Object.keys(data.locations)
                                      .map((l) => {
                                        const id = UUID();
                                        return `

                                      <h5>${l}</h5>

                                      <div class="accordion" id="accordion-${id}">
                                          <div class="accordion-item">
                                              <h6 class="accordion-header" id="heading-${id}">
                                                  <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                      data-bs-target="#collapse-${id}" aria-expanded="true"
                                                      aria-controls="collapse-${id}">
                                                      ${
                                                        content[
                                                          data.study_language
                                                        ].locations
                                                      }
                                                  </button>
                                              </h6>
                                              <div id="collapse-${id}" class="accordion-collapse collapse ${
                                          data.showAccordions ? 'show' : ''
                                        }"
                                                  aria-labelledby="heading-${id}" data-bs-parent="#accordion-${id}">
                                                  <div class="accordion-body">
                                                      ${data.locations[l]
                                                        .map((a) => {
                                                          return a
                                                            .map(
                                                              (ua) =>
                                                                `<p class="p--4">${ua.userAnswer}</p>`
                                                            )
                                                            .join('');
                                                        })
                                                        .join('')}
                                                  </div>
                                              </div>

                                          </div>
                                      </div>

                                      `;
                                      })
                                      .join('')}

                                </div>
                            </div>

                        </div>
                            `
                              : ''
                          }

                      ${
                        data.primary_contact[0] ||
                        data.primary_contact[1] ||
                        data.primary_contact[2]
                          ? `
                        <div id="body-section-2">
                            <h4>${content[data.study_language].contact}</h4>
                            <div id="body-section-2-subset-1" class="subset">
                                <div class="content no-head">
                                    <div class="callout-box">
                                        <p class="p--4"><strong>${
                                          data.primary_contact[0]
                                        }</strong></p>
                                        <p class="p--4">${
                                          data.primary_contact[1]
                                        }</p>
                                        <p class="p--4">Email: ${
                                          data.primary_contact[2]
                                        }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        `
                          : ''
                      }

                      <div id="body-section-3">
                          <h4>${content[data.study_language].what}</h4>
                          <div id="body-section-2-subset-2" class="subset">
                              <div class="content no-head">
                                  <p class="p--4">${
                                    content[data.study_language].whattext
                                  }</p>
                              </div>
                          </div>
                      </div>
                      <div id="body-section-4">
                          <h4>${content[data.study_language].why}</h4>
                          <div id="body-section-2-subset-3" class="subset">
                              <div class="content no-head">
                                  ${content[data.study_language].whytext}
                              </div>
                          </div>
                      </div>
                      ${
                        data.faq && data.faq.length > 0
                          ? `<div id="body-section-5">
                          <h4>${content[data.study_language].faq}</h4>
                          <div id="body-section-2-subset-4" class="subset">
                              <div class="content no-head">

                                 ${data.faq
                                   .map((a) => {
                                     const id = UUID();
                                     return `

                                   <div class="accordion" id="accordion-${id}">
                                       <div class="accordion-item">
                                           <h6 class="accordion-header" id="heading-${id}">
                                               <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                   data-bs-target="#collapse-${id}" aria-expanded="true"
                                                   aria-controls="collapse-${id}">
                                                   ${
                                                     data.study_language !=
                                                     'English'
                                                       ? faq_content[
                                                           data.study_language
                                                         ][
                                                           faq_content[
                                                             'English'
                                                           ].indexOf(a.text)
                                                         ]
                                                       : a.text
                                                   }
                                               </button>
                                           </h6>
                                           <div id="collapse-${id}" class="accordion-collapse collapse ${
                                       data.showAccordions ? 'show' : ''
                                     }"
                                               aria-labelledby="heading6" data-bs-parent="#accordion-${id}">
                                               <div class="accordion-body">
                                                   <p class="p--4">${
                                                     a.userAnswer
                                                   }</p>
                                               </div>
                                           </div>

                                       </div>
                                   </div>

                                `;
                                   })
                                   .join('')}

                              </div>
                          </div>
                      </div>`
                          : ''
                      }
                  </div>
              </div>
          </div>
      </section>

      <section class="footer-section">
          <div class="container">
              <div class="row text-center align-items-center">
                  <div class="col-sm-12 col-md-8">
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <img class="footer-graphic" src="assets/img/footer-people.png" />
                  </div>
              </div>
              <div>
      </section>
  </div>
  </div>
      <!-- Optional JavaScript; choose one of the two! -->

      <!-- Option 1: Bootstrap Bundle with Popper -->
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-gtEjrD/SeCtmISkJkNUaaKMoLD0//ElJ19smozuHV6z3Iehds+3Ulb9Bn9Plx0x4" crossorigin="anonymous">
      </script>

      <!-- Option 2: Separate Popper and Bootstrap JS -->
      <!--
      <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/js/bootstrap.min.js" integrity="sha384-Atwg2Pkwv9vp0ygtn1JAojH0nYbwNJLPhwyoVbhoPwBhjQPR5VtM2+xf0Uwh9KtT" crossorigin="anonymous"></script>
      -->

      <script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>

      <script>
        // Save the current console log function in case we need it.
        const _log = console.log;
        // Override the console
        console.log = function(...rest) {
          // window.parent is the parent frame that made this window
          window.parent.postMessage(
            {
              source: 'iframe',
              message: rest,
            },
            '*'
          );
          // Finally applying the console statements to saved instance earlier
          _log.apply(console, arguments);
        };
      </script>

      <script>

      $(document).ready(function(){

        $('a').click(function(e){
          e.preventDefault();
          const href = $(e.currentTarget).attr('href');
          $('html,body').animate({ scrollTop: $(href).offset().top }, 250);
        })
      })

      </script>

  </body>

  `;
};
