/* global MatgenGlobal, $ */
$(document).off('click keypress', '.skip-link--generator');
$(document).on('click keypress', '.skip-link--generator', (e) => {
  if (MatgenGlobal.UI.a11yClick(e) === true) {
    e.preventDefault();

    if (window.location.pathname.includes('website-data.html')) {
      $('#website-data').attr('tabindex', 0).focus();
    } else if ($('#tabfocus').length > 0) {
      $('#tabfocus').focus();
    } else if ($(e.target).attr('id')) {
      $(`#${$(e.target).attr('id')}`).focus();
    }
  }
});

/*window.tabKeyFired = false;
document.removeEventListener('keyup', detectTabKey);
document.addEventListener('keyup', detectTabKey);

function detectTabKey(e) {
  if ($(e.target).hasClass('form-control')) {
    return false;
  }
  if (
    e.keyCode == 9 &&
    !window.tabKeyFired &&
    $('#website-data-input').length > 0
  ) {
    const skipLink = document.getElementById('skip-link-id');
    let noSR = true;
    $(document).on('click', () => {
      // console.log('click detected');
      noSR = false;
    });
    if (noSR === true) {
      window.tabKeyFired = true;
      skipLink.focus();
      // console.log('tab key for SR pressed');
    }
  }
}*/
