export const content = {
  English: {
    title: 'Study title',
    welcome: 'Welcome',
    details: 'Study Details',
    name: 'Name of study',
    about: 'What is this study about?',
    right: 'Is the study right for me?',
    happen: "What's Involved?",
    long: 'Who can participate?',
    with: 'How many participants will be in this study?',
    risks: 'What are the risks of participation?',
    provided: 'Why should I participate?',
    funding: 'Who is funding this study?',
    investigators: 'Principal investigators',
    locations: 'Study location:',
    contact: 'To learn more, contact:',
    what: 'What is a clinical study?',
    why: "Why participate in an Alzheimer's clinical study?",
    faq: 'Common Questions',
    eligible:
      'Clinical studies differ in their goals and who can participate (“eligible”). Only people that meet a specific set of criteria can participate. To determine if this study is right for you, your doctor will ask you a set of questions before you can volunteer. The below list includes some criteria you may review now:',
    minage: 'Minimum Age',
    maxage: 'Maximum Age',
    yrsold: 'years old',
    gender: 'Gender',
    health_status: 'Health Status',
    condition: 'Condition',
    add: 'Additional requirements',
    primary: 'Primary Contact',
    whattext:
      'Clinical studies are medical research studies that involve people like you. They include people of all ages, genders, and diverse backgrounds. Some participants could be healthy, while others are at risk for or have a disease. Clinical studies are the main way that doctors find out if a new drug or treatment is safe and effective in people. Clinical studies also help doctors learn how to detect a disease early or to prevent a health problem. Clinical studies are the reason we have treatments for diseases such as breast cancer, stroke, and HIV/AIDS.',
    whytext:
      '<p class=p--4>People take part in clinical studies for many reasons. People who are healthy say they join a study to help others (and doctors) learn more about certain health problems. People with an illness or disease take part to help others, but also to possibly receive the newest treatment and to have extra care and attention from doctors and their team.</p><p class=p--4>Clinical studies offer hope for many people. Many people say participating in a clinical study is a way to play a more active role in their own health care. Whatever the reason, when you choose to participate in a clinical study, your contribution can help future generations lead healthier lives. Major medical breakthroughs could not happen without the generosity of clinical study participants—young and old.</p>',
    website_title: [
      'Protecting brain health',
      'Understanding brain health',
      'Understanding memory loss',
      'Help the next generation',
      'Changing family histories',
      'Understanding brain health',
      'Caring for a loved one with memory loss?',
      'A study focused on caregivers',
      "Let's make caregiving easier",
      'Caring for caregivers',
      'You are not alone.',
      'Making memory care easier',
      'Experiencing early stage memory loss or dementia?',
      'Having senior moments?',
      'Protecting brain health and independence.',
      'The fight against dementia starts here.',
      'Hope for generations to come.',
    ],
    website_subtitle: [
      'Help find treatments that work through a study.',
      'Join a brain health study.',
      'Join a study to protect quality of life.',
      'Join a study to check your brain health.',
      'Join a brain health study.',
      'Find expert support through a study.',
      'Get care partner support through a study.',
      'Join a study to improve caregiver well-being.',
      'Join a study to help reduce caregiver stress.',
      'Get support and education through a care partner program.',
      'Join a study designed to help caregivers.',
      'Experiencing early stage memory loss or dementia?',
      'Join a study to manage memory loss.',
      'Join a brain health study to learn more about treatment options.',
      "Be a part of a study that could change your family's future.",
    ],
    phase_text: [
      'Phase I - Safety',
      'Phase II - Effectiveness',
      'Phase III - Scale',
      'Phase IV - Monitoring',
      'Not Applicable',
    ],
    phase_sub_text: [
      'Doctors test a new drug or treatment for the first time to learn about its safety and any side effects. Testing is done with a small group of people (20-80).',
      'The new drug or treatment is tested with a larger group of people (100-300) to find out if it is effective and to learn more about its safety.',
      'The new drug or treatment is given to large groups of people (1,000-3,000) to compare its effectiveness and safety with standard or similar treatments. If the FDA agrees that the trial results are positive, it will approve the new drug or treatment.',
      "After a drug is approved by the FDA and made available to the public, doctors track its safety in the general population to learn more about the drug or treatment's benefits and best use.",
      'Phases are not applicable to this study at this time.',
    ],
    gender_options: ['Male', 'Female', 'Non-Binary', 'All'],
    health_status_options: [
      'Healthy adults',
      'Healthy adults at risk for dementia',
      'Adults with Down Syndrome',
      "Adults with Alzheimer's or other dementia",
      "Caregivers of adults with Alzheimer's or other dementia",
      'Caregivers of adults with Down Syndrome',
    ],
    inv_name: 'Name',
    inv_position: 'Position',
    inv_location: 'Location',
    inv_email: 'Email',
    loc_name: 'Location name',
    loc_add1: 'Address Line 1',
    loc_add2: 'Address Line 2',
    loc_state: 'State',
    loc_city: 'City',
    loc_status: 'Status (ex. Recruiting, Not-recruiting, etc.)',
    pc_name: 'Primary contact name',
    pc_phone: 'Primary contact phone number',
    pc_email: 'Primary contact email',
  },
  Spanish: {
    title: 'Título del estudio',
    welcome: 'Bienvenido',
    details: 'Detalles del estudio',
    name: 'Nombre del estudio',
    about: '¿De qué se trata este estudio?',
    right: '¿Es este estudio adecuado para mí?',
    happen: "What's Involved?",
    long: '¿Quién puede participar?',
    with: '¿Cuántos participantes serán parte de este estudio?',
    risks: '¿Cuáles son los riesgos al ser participante?',
    provided: '¿Por qué yo debería participar?',
    funding: '¿Quién está financiando este estudio?',
    investigators: 'Investigadores principales',
    locations: 'Ubicación del estudio:',
    contact: 'Para más información, contactar a:',
    what: '¿Qué son estudios clínicos?',
    why: '¿Por qué participar en un estudio clínico de Alzheimer?',
    faq: 'Preguntas frecuentes',
    eligible:
      'Los estudios clínicos difieren en sus objetivos y en quiénes pueden participar (\\elegibles\\). Solo pueden participar personas que cumplan con un conjunto específico de criterios. Para determinar si este estudio es adecuado para usted, su médico le hará una serie de preguntas antes de que pueda ofrecerse como voluntario. La siguiente lista son algunos criterios que puede responder ahora:',
    minage: 'Edad mínima',
    maxage: 'Edad máxima',
    yrsold: 'Años',
    gender: 'Género',
    health_status: 'Estado de salud',
    condition: 'Condición',
    add: 'Requisitos adicionales',
    primary: 'Contacto principal',
    whattext:
      'Estudios clínicos son estudios de investigación médica con personas como usted. Los participantes pueden ser adultos saludables, cuidadores o personas que padecen pérdida de memoria o Alzheimer. Incluyen personas de todas las edades y diferentes antecedentes. Algunos tienen como objetivo determinar si un nuevo medicamento o tratamiento es seguro y efectivo. Otras buscan encontrar formas de diagnosticar más temprano o prevenir un problema de salud en la población. Los estudios clínicos son la razón por la que contamos con tratamientos para enfermedades como el cáncer de mama, los accidentes cerebrovasculares y el VIH / SIDA.',
    whytext:
      '<p class=p--4> Las personas participan en estudios clínicos por muchas razones. Las personas sanas dicen que se unen a un estudio para ayudar a otros (y médicos) a aprender más sobre ciertos problemas de salud. Las personas con una enfermedad o dolencia participan para ayudar a otros, pero también para posiblemente recibir el tratamiento más nuevo y para recibir atención y cuidados adicionales por parte de los médicos y su equipo. </p> <p class=p--4> Los estudios clínicos ofrecen esperanza a muchas personas. Mucha gente dice que participar en un estudio clínico es una forma de desempeñar un papel más activo en su propia atención médica. Cualquiera sea el motivo, cuando elige participar en un estudio clínico, su contribución puede ayudar a las generaciones futuras a llevar una vida más saludable. Los grandes avances médicos no podrían suceder sin la generosidad de los participantes del estudio clínico, tanto jóvenes como mayores. </p>',
    website_title: [
      'Protegiendo la salud del cerebro',
      'Entendiendo la salud del cerebro',
      'Entendiendo la pérdida de memoria',
      'Ayudar a la próxima generación',
      'Cambiando la historia de familias',
      'Entendiendo la salud del cerebro',
      '¿Está cuidando a un ser querido que sufre de pérdida de memoria?',
      'Un estudio focalizado en los cuidadores',
      'Hagamos del cuidado una tarea más fácil',
      'Cuidar a los cuidadores',
      'Usted no está solo',
      'Hacer más fácil el cuidado de los que pierden la memoria',
      '¿Está teniendo episodios de pérdida de memoria o demencia temprana?',
      '¿Está teniendo “senior moments”?',
      'Protegiendo la salud del cerebro y la independencia.',
      'La lucha contra la demencia comienza aquí.',
      'Esperanza para las futuras generaciones.',
    ],
    website_subtitle: [
      'Ayude a encontrar tratamientos que funcionen a través de un estudio.',
      'Participe en un estudio sobre salud del cerebro.',
      'Participe en un estudio para proteger la calidad de vida.',
      'Participe en un estudio para determinar el estado de salud de su cerebro.',
      'Participe en un estudio sobre salud del cerebro.',
      'Encuentre información de expertos a través de un estudio.',
      'Encuentre apoyo para los cuidadores a través de un estudio.',
      'Participe en un estudio para mejorar la calidad de vida de cuidadores.',
      'Participe en un estudio para ayudar a reducir el estrés de los cuidadores.',
      'Obtenga apoyo y educación a través de un programa para los cuidadores.',
      'Participe en un estudio para ayudar a los cuidadores.',
      '¿Está teniendo episodios de pérdida de memoria o demencia temprana?',
      'Participe en un estudio para afrontar la pérdida de memoria.',
      'Participe en un estudio de salud del cerebro para aprender más sobre opciones de tratamientos.',
      'Participe en el más reciente estudio sobre demencia para conocer sus opciones.',
    ],
    phase_text: [
      'Fase 1: Seguridad',
      'Fase 2: Efectividad',
      'Fase 3: Escala',
      'Fase 4: Monitoreo',
      'No aplica',
    ],
    phase_sub_text: [
      'Los doctores prueban una nueva medicina o tratamiento por primera vez para conocer sobre su seguridad y cualquier efecto secundario. La prueba es conducida con un pequeño grupo de personas (20-80)',
      'La nueva medicina o tratamiento es probado con un grupo de personas más grande (100-300) para determinar si es efectiva y conocer más sobre su seguridad.',
      'La nueva medicina o tratamiento es distribuida a grandes grupos de personas (1,000-3,000) para comparar su efectividad y seguridad con otros tratamientos estándar o similares. Si la FDA acepta que los resultados de las pruebas son positivos, entonces aprobará la nueva medicina o tratamiento.',
      'Luego que una medicina es aprobada por la FDA, estará disponible al público, y los doctores pueden hacer seguimiento a su efectividad en la población en general para conocer más sobre los beneficios de la medicina o del tratamiento y su mejor uso',
      'Fases no aplican a este estudio en este momento',
    ],
    gender_options: ['Masculino', 'Femenino', 'No Binario', 'Todos'],
    health_status_options: [
      'Adultos saludables',
      'Adultos saludables en riesgo de demencia',
      'Adultos con síndrome de Down',
      'Adultos con Alzheimer u otro tipo de demencia',
      'Cuidadores de adultos con Alzheimer u otro tipo de demencia',
      'Cuidadores de adultos con síndrome de Down',
    ],
    inv_name: '(ES) Name',
    inv_position: '(ES) Position',
    inv_location: '(ES) Location',
    inv_email: '(ES) Email',
    loc_name: '(ES) Location name',
    loc_add1: '(ES) Address Line 1',
    loc_add2: '(ES) Address Line 2',
    loc_state: '(ES) State',
    loc_city: '(ES) City',
    loc_status: '(ES) Status (ex. Recruiting, Not-recruiting, etc.)',
    pc_name: '(ES) Primary contact name',
    pc_phone: '(ES) Primary contact phone number',
    pc_email: '(ES) Primary contact email',
  },
  Hindi: {
    title: 'अध्ययन शीर्षक',
    welcome: 'स्वागत',
    details: 'अध्ययन के विवरण',
    name: 'अध्ययन का नाम',
    about: 'यह अध्ययन किस चीज़ के बारे में है?',
    right: 'क्या यह अध्ययन मेरे लिए सही है?',
    happen: "What's Involved?",
    long: 'कौन हिस्सा ले सकता है?',
    with: 'इस अध्ययन में कितने हिस्सा लेने वाले हैं?',
    risks: 'हिस्सा लेने के जोखिम क्या हैं?',
    provided: 'मुझे हिस्सा क्यों लेना चाहिए?',
    funding: 'आपके अध्ययन का वित्तपोषण कौन कर रहा है?',
    investigators: 'प्राथमिक संपर्क नाम',
    locations: 'अध्ययन का स्थान:',
    contact: 'और जानने के लिए इनसे संपर्क करें:',
    what: 'नैदानिक अध्ययन क्या हैं?',
    why: 'नैदानिक अध्ययन में भाग क्यों लें?',
    faq: 'सामान्य प्रश्न',
    eligible:
      'क्लीनिकल अध्ययन अपने लक्ष्यों के मामले में अलग-अलग होते हैं और वे इस मामले में भी अलग-अलग होते हैं कि कौन उनमें भाग ले सकता है (“पात्र”)। केवल कुछ विशिष्ट मानदंडों को संतुष्ट करने वाले लोग ही इनमें भाग ले सकते हैं। यह अध्ययन आपके लिए सही है या नहीं यह तय करने के लिए, आपके चिकित्सक आपसे कुछ प्रश्न पूछेंगे, उसके बाद ही आप इसमें स्वेच्छा से भाग ले सकते हैं। नीचे कुछ मानदंड दिए गए हैं जिनके आप अभी उत्तर दे सकते हैं:',
    minage: 'न्यूनतम आयु',
    maxage: 'अधिकतम आयु',
    yrsold: 'वर्षीय',
    gender: 'लिंग',
    health_status: 'स्वास्थ्य की स्थिति',
    condition: 'स्थिति [स्वास्थ्य]',
    add: 'अतिरिक्त आवश्यकताएं',
    primary: 'प्राथमिक संपर्क नाम',
    whattext:
      'क्लीनिकल अध्ययन चिकित्सीय शोध अध्ययन होते हैं जिनमें आप जैसे लोगों को शामिल किया जाता है। इनमें सभी आयु वर्गों, लिंगों और विविध पृष्ठभूमियों के लोग शामिल होते हैं। कुछ सहभागी स्वस्थ हो सकते हैं, वहीं कुछ किसी रोग के जोखिम में या उससे ग्रस्त हो सकते हैं। क्लीनिकल अध्ययन वह मुख्य तरीका हैं जिनसे चिकित्सक यह पता लगाते हैं कि कोई नई दवा या उपचार लोगों में निरापद (हानिरहित) और प्रभावी है या नहीं। क्लीनिकल अध्ययन चिकित्सकों को यह जानने में भी मदद देते हैं कि किसी रोग का आरंभ में ही पता कैसे लगाया जाए या किसी स्वास्थ्य समस्या की रोकथाम कैसे की जाए। क्लीनिकल अध्ययनों की बदौलत ही हमारे पास स्तन कैंसर, स्ट्रोक और HIV/एड्स जैसे रोगों के उपचार हैं।',
    whytext:
      'लोग कई कारणों से क्लीनिकल अध्ययनों में भाग लेते हैं। स्वस्थ लोग दूसरों को (और चिकित्सकों को) कुछ स्वास्थ्य समस्याओं के बारे में और जानने में मदद देने के लिए अध्ययन में शामिल होते हैं। किसी रुग्णता या रोग से ग्रस्त लोग दूसरों की मदद करने के लिए, और संभावित रूप से नवीनतम उपचार पाने और चिकित्सकों व उनकी टीम से अतिरिक्त देखभाल और ध्यान पाने के लिए भी इनमें भाग लेते हैं।',
    website_title: [
      'मस्तिष्क स्वास्थ्य की रक्षा करना',
      'मस्तिष्क स्वास्थ्य को समझना',
      'स्मृति हानि को समझना',
      'आने वाली पीढ़ी की मदद करें',
      'पारिवारिक इतिहास बदलना',
      'मस्तिष्क स्वास्थ्य को समझना',
      'याददाश्त खो चुके किसी प्रियजन की देखभाल कर रहे हैं?',
      'देखभालकर्ताओं के लिए केंद्रित अध्ययन',
      'आइए, देखभाल करना आसान बनाएं',
      'देखभालकर्ताओं की देखभाल',
      'आप अकेले नहीं हैं',
      'आइए, देखभाल करना आसान बनाएं',
      'क्या आप प्रारंभिक चरण की स्मृति हानि या मनोभ्रंश(पागलपन) से जूझ रहे हैं?',
      'क्या उम्र के साथ-साथ आपकी याददाश्त कम हो रही है?',
      'मस्तिष्क स्वास्थ्य और स्वतंत्रता की रक्षा करना।',
      'मनोभ्रंश के खिलाफ लड़ाई यहाँ से शुरू होती है।',
      'आने वाली पीढ़ियों के लिए उम्मीद।',
    ],
    website_subtitle: [
      'किसी अध्ययन के माध्यम से काम करने वाले उपचार खोजने में सहायता करें।',
      'किसी मस्तिष्क स्वास्थ्य अध्ययन में शामिल हों।',
      'जीवन शैली की रक्षा के लिए किसी अध्ययन में शामिल हों।',
      'अपने मस्तिष्क के स्वास्थ्य की जांच के लिए किसी अध्ययन में शामिल हों।',
      'किसी मस्तिष्क स्वास्थ्य अध्ययन में शामिल हों।',
      'किसी अध्ययन के माध्यम से विशेषज्ञ अंतर्दृष्टि प्राप्त करें।',
      'किसी अध्ययन के माध्यम से देखभालकर्ता समर्थन प्राप्त करें।',
      'देखभालकर्ताओं की भलाई में सुधार के लिए डिज़ाइन किए गए किसी अध्ययन में शामिल हों।',
      'देखभालकर्ता की तनाव को कम करने में मदद करने के लिए अध्ययन में शामिल हों।',
      'देखभालकर्ता कार्यक्रम के माध्यम से सहायता और शिक्षा प्राप्त करें।',
      'देखभालकर्ताओं की मदद करने के लिए डिज़ाइन किए गए किसी अध्ययन में शामिल हों।',
      'क्या आप प्रारंभिक चरण की स्मृति हानि या मनोभ्रंश(पागलपन) से जूझ रहे हैं?',
      'स्मृति हानि की प्रबंधन के लिए किसी अध्ययन में शामिल हों।',
      'उपचार के विकल्पों के बारे में अधिक जानने के लिए किसी मस्तिष्क स्वास्थ्य अध्ययन में शामिल हों।',
      'किसी ऐसे अध्ययन का हिस्सा बनें, जो आपके परिवार का भविष्य बदल सकता है।',
    ],
    phase_text: [
      'चरण 1: सुरक्षा',
      'चरण 2: प्रभावशीलता',
      'चरण 3: स्केल',
      'चरण 4: निगरानी',
      'लागू नहीं',
    ],
    phase_sub_text: [
      'चिकित्सक किसी नई दवा या उपचार की निरापदता और किन्हीं भी दुष्प्रभावों के बारे में जानने के लिए उसका पहली बार परीक्षण करते हैं। परीक्षण लोगों के एक छोटे समूह (20-80) के साथ किया जाता है।',
      'नई दवा या उपचार प्रभावी है या नहीं यह पता लगाने और उसकी निरापदता के बारे में और जानने के लिए उसका परीक्षण लोगों के एक अपेक्षाकृत बड़े समूह (100-300) के साथ किया जाता है।',
      'नई दवा या उपचार की प्रभावशीलता और निरापदता की तुलना मानक या मिलते-जुलते उपचारों के साथ करने के लिए उसे लोगों के विशाल समूहों (1,000-3,000) को दिया जाता है। यदि FDA (खाद्य एवं औषध प्रशासन) इस बात पर सहमत होता है कि परीक्षण के परिणाम सकारात्मक हैं, तो वह नई दवा या उपचार को अनुमोदन देता है।',
      'एफडीए द्वारा किसी दवा को अनुमोदित करने और जनता के लिए उपलब्ध कराए जाने के बाद, डॉक्टर दवा या उपचार के लाभों और सर्वोत्तम उपयोग के बारे में अधिक जानने के लिए सामान्य आबादी में इसकी सुरक्षा को ट्रैक करते हैं।',
      'इस समय इस अध्ययन के लिए चरण लागू नहीं हैं।',
    ],
    gender_options: ['पुरुष', 'महिला', 'Non-Binary', 'सभी'],
    health_status_options: [
      'स्वस्थ वयस्क',
      'मनोभ्रंश (डिमेंशिया) के जोखिम में मौजूद स्वस्थ वयस्क',
      'डाउन सिंड्रोम से ग्रस्त वयस्क',
      'एल्ज़ाइमर या अन्य प्रकार के मनोभ्रंश (डिमेंशिया) से ग्रस्त वयस्क',
      'एल्ज़ाइमर या अन्य प्रकार के मनोभ्रंश (डिमेंशिया) से ग्रस्त वयस्कों के देखभालकर्ता',
      'डाउन सिंड्रोम से ग्रस्त वयस्कों के देखभालकर्ता',
    ],
    inv_name: '(HI) Name',
    inv_position: '(HI) Position',
    inv_location: '(HI) Location',
    inv_email: '(HI) Email',
    loc_name: '(HI) Location name',
    loc_add1: '(HI) Address Line 1',
    loc_add2: '(HI) Address Line 2',
    loc_state: '(HI) State',
    loc_city: '(HI) City',
    loc_status: '(HI) Status (ex. Recruiting, Not-recruiting, etc.)',
    pc_name: '(HI) Primary contact name',
    pc_phone: '(HI) Primary contact phone number',
    pc_email: '(HI) Primary contact email',
  },
  Tagalog: {
    title: 'Pamagat ng pinagaaralan',
    welcome: 'Maligayang pagdating',
    details: 'Mga Detalye ng Pinag-aaralan',
    name: 'Pangalan ng Pinag-aaralan',
    about: 'Tungkol saan ang pag-aaral na ito?',
    right: 'Angkop ba ang pag-aaral na ito para sa akin?',
    happen: 'Ano-ano ang kabilang?',
    long: 'Sino ang maaaring sumali?',
    with: 'Ilang kalahok ang kasama sa pag-aaral na ito?',
    risks: 'Ano-ano ang panganib ng pakikilahok?',
    provided: 'Bakit ako dapat sumali?',
    funding: 'Sino ang magpopondo ng iyong pag-aaral?',
    investigators: 'Sino ang mga tagapagsiyasat ng iyong pag-aaral?',
    locations: 'Lokasyon ng pag-aaral:',
    contact: 'Para mas matuto pa, makipag-ugnayan sa:',
    what: 'Ano ang mga klinikal na pag-aaral?',
    why: 'Bakit mahalaga ang paglahok sa isang klinikal na pag-aaral?',
    faq: 'Mga Karaniwang Tanong',
    eligible:
      'Ang mga klinikal na pag-aaral ay nagkakaiba sa kanilang mga layunin at kung sino ang maaaring lumahok (“kwalipikado”). Tanging ang mga taong nakakatugon sa isang partikular na set ng mga kriterya ang maaaring lumahok. Upang matukoy kung ang pag-aaral na ito ay angkop para sa iyo, ikaw ay tatanungin ng iyong doktor ng isang set ng mga tanong bago ka makapagboluntaryo. Ang listahan sa ibaba ay ilang kriterya na maaari mong sagutin ngayon:',
    minage: 'Pinakamababang Edad',
    maxage: 'Pinakamataas na Edad',
    yrsold: 'taong gulang',
    gender: 'Kasarian',
    health_status: 'Katayuan sa Kalusugan',
    condition: 'Kalagayan',
    add: 'Mga karagdagang kinakailangan',
    primary: 'ng pangunahing kontak',
    whattext:
      'Ang mga klinikal na pag-aaral ay mga medikal na pananaliksik na pag-aaral na kinasasangkutan ng mga taong katulad mo. Kabilang dito ang mga tao anuman ang edad, kasarian, at magkakaibang background. Maaaring malusog ang ilang kalahok, habang ang iba ay nasa panganib para sa o mayroong sakit. Ang mga klinikal na pag-aaral ay ang pangunahing paraan upang malaman ng mga doktor kung ang isang bagong gamot o paggamot ay ligtas at epektibo sa mga tao. Tinutulungan din ng mga klinikal na pag-aaral ang mga doktor na matutunan kung paano matukoy nang maaga ang isang sakit o upang maiwasan ang problema sa kalusugan. Ang mga klinikal na pag-aaral ang dahilan kung bakit mayroon tayong mga paggamot para sa mga sakit tulad ng kanser sa suso, stroke, at HIV/AIDS.',
    whytext:
      'Ang mga tao ay nakikibahagi sa mga klinikal na pag-aaral para sa maraming dahilan. Sinasabi ng mga malulusog na tao na sumasali sila sa isang pag-aaral upang tulungan ang iba (at mga doktor) na matuto pa tungkol sa ilang mga problema sa kalusugan. Ang mga taong may karamdaman o sakit ay nakikibahagi upang tumulong sa iba, ngunit gayundin upang posibleng makatanggap ng pinakabagong paggamot at magkaroon ng karagdagang pangangalaga at atensyon mula sa mga doktor at kanilang grupo.',
    website_title: [
      'Pagprotekta sa kalusugan ng utak',
      'Pag-unawa sa kalusugan ng utak',
      'Pag-unawa sa pagkawala ng memorya',
      'Tulungan ang susunod na henerasyon',
      'Pagbago ng kasaysayan ng pamilya',
      'Pag-unawa sa kalusugan ng utak',
      'Nag-aalaga sa isang mahal sa buhay na may pagkawala ng memorya?',
      'Isang pag-aaral na nakatuon sa mga tagapag-alaga',
      'Gawin nating mas madali ang pag-aalaga',
      'Pag-aalaga sa mga tagapag-alaga',
      'Hindi ka nag-iisa',
      'Pinapadali ang pangangalaga sa memorya',
      'Nakakaranas ng unang bahagi ng pagkawala ng memorya o demensya?',
      'Nagkakaroon ng panandaliang pagkalimot o pagkalito [senior moments]?',
      'Pagprotekta sa kalusugan ng utak at pagsasarili.',
      'Dito nagsisimula ang paglaban sa demensya.',
      'Pag-asa para sa mga susunod pang henerasyon.',
    ],
    website_subtitle: [
      'Tumulong sa paghahanap ng mga paggamot na gumagana sa pamamagitan ng isang pag-aaral.',
      'Sumali sa isang pag-aaral sa kalusugan ng utak.',
      'Sumali sa isang pag-aaral upang maprotektahan ang kalidad ng buhay.',
      'Sumali sa isang pag-aaral upang suriin ang kalusugan ng iyong utak.',
      'Sumali sa isang pag-aaral sa kalusugan ng utak.',
      'Maghanap ng ekspertong pang-unawa sa pamamagitan ng isang pag-aaral.',
      'Kumuha ng suporta ng tagapag-alaga sa pamamagitan ng isang pag-aaral.',
      'Sumali sa isang pag-aaral na idinisenyo upang mapabuti ang kapakanan ng tagapag-alaga.',
      'Sumali sa isang pag-aaral upang makatulong na mabawasan ang stress ng tagapag-alaga.',
      'Kumuha ng suporta at edukasyon sa pamamagitan ng isang programa ng tagapag-alaga.',
      'Sumali sa isang pag-aaral na idinisenyo upang matulungan ang mga',
      'Nakakaranas ng unang bahagi ng pagkawala ng memorya o demensya?',
      'Sumali sa isang pag-aaral upang mabantayan ang pagkawala ng memorya.',
      'Sumali sa isang pag-aaral sa kalusugan ng utak upang matuto nang higit pa tungkol sa mga opsyon sa paggamot.',
      'Maging bahagi ng isang pag-aaral na maaaring magbago sa kinabukasan ng iyong pamilya.',
    ],
    phase_text: [
      'Unang Bahagi: Kaligtasan',
      'Ikalawang Bahagi: Pagka-epektibo',
      'Ikatlong Bahagi: Antas',
      'Ikaapat na Bahagi: Pagsubaybay',
      'Hindi Naaangkop',
    ],
    phase_sub_text: [
      'Sinusuri ng mga doktor ang isang bagong gamot o paggamot sa unang pagkakataon upang malaman ang tungkol sa kaligtasan at anumang mga hindi magandang epekto nito. Ang pagsusuri ay ginagawa sa isang maliit na grupo ng mga tao (20-80).',
      'Ang bagong gamot o paggamot ay sinusuri sa isang mas malaking grupo ng mga tao (100-300) upang malaman kung ito ay epektibo at upang matuto nang higit pa tungkol sa kaligtasan nito.',
      'Ang bagong gamot o paggamot ay ibinibigay sa malalaking grupo ng mga tao (1,000-3,000) upang ikumpara ang pagiging epektibo at kaligtasan nito sa karaniwan o katulad na mga paggamot. Kung sumang-ayon ang FDA na positibo ang mga resulta ng pagsusuri, aaprubahan nito ang bagong gamot o paggamot.',
      'Pagkatapos maaprubahan ng FDA ang isang gamot at maging available sa publiko, sinusubaybayan ng mga doktor ang kaligtasan nito sa pangkalahatang populasyon upang matuto nang higit pa tungkol sa mga benepisyo at pinakamahusay na paggamit ng gamot o paggamot.',
      'Ang mga yugto ay hindi naaangkop sa pag-aaral na ito sa ngayon.',
    ],
    gender_options: ['Lalaki', 'Babae', 'Non-Binary', 'Lahat'],
    health_status_options: [
      'Mga Malusog na Nasa Hustong Gulang',
      'Mga malusog na nasa hustong gulang na nanganganib na magkaroon ng demensya',
      'Mga nasa hustong gulang na may Down Syndrome',
      "Mga nasa hustong gulang na may Alzheimer's o iba pang demensya",
      "Mga tagapag-alaga ng mga nasa hustong gulang na may Alzheimer's o iba pang demensya",
      'Mga tagapag-alaga ng mga nasa hustong gulang na may Down Syndrome',
    ],
    inv_name: '(TA) Name',
    inv_position: '(TA) Position',
    inv_location: '(TA) Location',
    inv_email: '(TA) Email',
    loc_name: '(TA) Location name',
    loc_add1: '(TA) Address Line 1',
    loc_add2: '(TA) Address Line 2',
    loc_state: '(TA) State',
    loc_city: '(TA) City',
    loc_status: '(TA) Status (ex. Recruiting, Not-recruiting, etc.)',
    pc_name: '(TA) Primary contact name',
    pc_phone: '(TA) Primary contact phone number',
    pc_email: '(TA) Primary contact email',
  },
  Chinese: {
    title: '研究名称',
    welcome: '欢迎',
    details: '调查详情',
    name: '研究名称',
    about: '这项研究是关于什么的？',
    right: '此项调查是否适合我？',
    happen: '研究涉及什么？',
    long: '谁可以参与研究？',
    with: '这项研究将有多少参与者？',
    risks: '参与有哪些风险？',
    provided: '我为什么要参与研究？',
    funding: '您的调查的资助方是谁？',
    investigators: '您的调查的开展地点？',
    locations: '研究地点：',
    contact: '如需了解更多信息，请联系：',
    what: '什么是临床研究？',
    why: '为什么要参加临床研究？',
    faq: '常见问题',
    eligible:
      '各种临床研究在其目标和参与者（“符合条件者”）方面有所不同。只有符合某一组特定标准的人士才能参与。为了确定此项研究是否适合您，您的医生会在您自愿参与之前询问您一组问题。以下列出的是您现在可以回答的一些关于标准的问题：',
    minage: '最小年龄',
    maxage: '最大年龄',
    yrsold: '年岁',
    gender: '性别',
    health_status: '健康状况',
    condition: '状况[健康]',
    add: '其他要求',
    primary: '主要联系人姓名',
    whattext:
      '临床研究是关于像您这样的人士参与的医学研究。临床研究纳入所有年龄、性别和不同背景的人士。一些参与者可能是健康的，而另一些则有患病的风险或已患有疾病。临床研究是医生探索新药或治疗方法对人体是否安全有效的主要方式。临床研究还帮助医生了解如何早期发现疾病或预防健康问题。临床研究使我们找到了乳腺癌、中风和艾滋病等疾病的治疗方法。',
    whytext:
      '人们之所以参与临床研究是有很多原因的。身体健康的人士表示他们参与研究是为了帮助他人（和医生）更多地了解某些健康问题。患有疾病的人士参与研究是为了帮助他人，但也可能接受最新的治疗方法，并得到医生及其团队的额外照顾和关注。',
    website_title: [
      '保护大脑健康',
      '了解大脑健康',
      '了解记忆丧失',
      '帮助下一代',
      '改变家族史',
      '了解大脑健康',
      '照顾患有记忆丧失症的亲人？',
      '一项专注于照顾者的调查',
      '让护理工作变得更轻松',
      '关爱照顾者',
      '您并不孤单',
      '让护理工作变得更轻松',
      '正在经历早期的记忆丧失 或失智症吗？',
      '患有老年失忆症吗？',
      '保护大脑健康和独立性。',
      '一起开始战胜失智症。',
      '为后代带来希望。',
    ],
    website_subtitle: [
      '通过调查帮助找到有效的治疗方法。',
      '参与大脑健康调查。',
      '参与保障生活质量的调查。',
      '参与一项可检查大脑健康的调查。',
      '参与大脑健康调查。',
      '通过调查获得专家见解。',
      '通过调查获得照顾者的支持。',
      '参与一项旨在改善照顾者福祉的调查。',
      '参与一项旨在帮助减少照顾者压力的调查。',
      '通过照顾者计划获得相关的支持和教育。',
      '参与一项旨在帮助照顾者的调查。',
      '正在经历早期的记忆丧失 或失智症吗？',
      '参与调查，以管理记忆丧失。',
      '参与大脑健康调查，了解更多治疗方案。',
      '参与调查，改变您家人的未来。',
    ],
    phase_text: [
      '第I期：安全性',
      '第II期：有效性',
      '第III期：扩大',
      '第Ⅳ期：监查',
      '不适用',
    ],
    phase_sub_text: [
      '医生首次测试一种新药或治疗方法，以了解其安全性和任何副作用。对一小群组（20-80人）进行测试。',
      '对一较大群组（100-300人）进行新药或治疗方法的测试，以确定其是否有效，并进一步了解其安全性。',
      '对一更大群组（1,000-3,000人）施用新药或治疗方法，与标准或类似治疗方法进行有效性和安全性的比较。如果美国食品药品监督管理局（FDA）同意试验结果良好，将批准该新药或治疗方法。',
      '药物获得 FDA 批准并向公众提供后，医生会跟踪其在一般人群中的安全性，以了解有关该药物或治疗的益处和最佳用途的更多信息。',
      '目前阶段不适用于本研究。',
    ],
    gender_options: ['男性', '女性', '非二元性別', '所有'],
    health_status_options: [
      '健康成年人',
      '有失智症风险的健康成年人',
      '患有唐氏综合症的成年人',
      '患有阿尔茨海默氏症或其他失智症的成年人',
      '患有阿尔茨海默氏症或其他失智症的成年人的照顾者',
      '患有唐氏综合症的成年人的照顾者',
    ],
    inv_name: '(CH) Name',
    inv_position: '(CH) Position',
    inv_location: '(CH) Location',
    inv_email: '(CH) Email',
    loc_name: '(CH) Location name',
    loc_add1: '(CH) Address Line 1',
    loc_add2: '(CH) Address Line 2',
    loc_state: '(CH) State',
    loc_city: '(CH) City',
    loc_status: '(CH) Status (ex. Recruiting, Not-recruiting, etc.)',
    pc_name: '(CH) Primary contact name',
    pc_phone: '(CH) Primary contact phone number',
    pc_email: '(CH) Primary contact email',
  },
};

export const faq_content = {
  English: [
    "What's being tested?",
    'What are the next steps if I choose to participate in the study?',
    'How long will I be in the study?',
    'Are there risks to being in the study?',
    'How will this affect my everyday life?',
    'Are there any costs to participate?',
    'What does it mean to be in a randomized study?',
    'If I sign up for the study, can I change my mind later?',
  ],
  Spanish: [
    '¿Qué se está probando?',
    '¿Cuáles son los próximos pasos si yo decido participar en el estudio?',
    '¿Por cuánto tiempo voy a estar en este estudio?',
    '¿Hay riesgos al ser parte del estudio?',
    '¿Cómo va a afectar mi vida diaria?',
    '¿Hay algún costo al ser participante?',
    '¿Qué significa estar en un estudio aleatorio?',
    'Si decido ser parte del estudio, ¿puedo cambiar de opinión más adelante?',
  ],
  Chinese: [
    '研究正在测试什么？',
    '如果我选择参与研究，接下来的步骤是什么？',
    '在此项调查中，我的参与时间有多久?',
    '参与研究有风险吗？',
    '这将如何影响我的日常生活？',
    '参与有任何费用吗？',
    '参与随机研究意味着什么？',
    '如果我报名参加研究，我以后可以改变主意吗？',
  ],
  Hindi: [
    'किस चीज़ का परीक्षण किया जा रहा है?',
    'अगर मैं हिस्सा लेना चुनुं तो अगले कदम क्या हैं?',
    'मैं इस अध्ययन में कब तक रहूंगा/गी?',
    'क्या अध्ययन में हिस्सा लेने के जोखिम हैं?',
    'इससे मेरा रोज़ाना का जीवन कैसे प्रभावित होगा?',
    'क्या हिस्सा लेने की कोई लागत है?',
    'किसी बिना निर्धारित क्रम (रैंडमाइज़्ड) अध्ययन में हिस्सा लेने का मतलब क्या है?',
    'अगर मैं अध्ययन के लिए साइन अप करूँ, तो क्या मैं बाद में अपना मन बदल सकता/सकती हूँ?',
  ],
  Tagalog: [
    'Ano ang sinusuri?',
    'Ano-ano ang susunod na hakbang kung sasali ako sa pag-aaral?',
    'Gaano ako katagal sa pag-aaral na ito?',
    'Ano-ano ang panganib ng pagsali sa pag-aaral?',
    'Paano nito maaapektuhan ang pang-araw-araw kong pamumuhay?',
    'May babayaran ba sa pagsali?',
    'Anong ibig sabihin ng pagsali sa isang randomized na pag-aaral?',
    'Kung nakapagpalista na ako sa pag-aaral, puwede pa ba akong magbago ng pasiya?',
  ],
};
