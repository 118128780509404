export const alertHeader = ({ message, icon } = {}) => {
  return `
  <h5><i class="${icon}"></i> ${message}</h5>
  `;
};

export const noResults = `
${alertHeader({ message: 'No Results', icon: 'fa-solid fa-empty-set' })}
<p>There were no results matching the given criteria.</p>
`;

export const errorHeader = alertHeader({
  message: "We're Sorry, There Has Been An Error...",
  icon: 'fa-regular fa-cloud-exclamation',
});

export const noTags = `
  ${errorHeader}
  <p>Use our <a class="link-danger" href="/">home page</a> or <a class="link-danger" href="/create/materials.html">create page</a> to begin creating materials.</p>
  <p>If you continue experiencing issues, please <a class="link-danger" href="/helpdesk.html" target="_blank">contact support</a>.</p>
`;

export const contactSupport =
  '<a class="link-danger" href="/helpdesk.html" target="_blank">contact support</a>';

export const displayError = `There was an error displaying the data. Please ${contactSupport}.`;
